@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dotted-border {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920.5' height='2' viewBox='0 0 1920.5 2'%3E%3Cline id='Line_66' data-name='Line 66' x1='1920.5' transform='translate(0 1)' fill='none' stroke='%23707070' stroke-width='2' stroke-dasharray='3 3'/%3E%3C/svg%3E%0A");
  height: 1px;
  width: 100vw;
}

.dotted-border_green {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23879D7F' stroke-width='1' stroke-dasharray='2' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e");
  height: 1px;
  width: 100vw;
}
